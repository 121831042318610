/*
 * Start Bootstrap - One Page Wonder (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Lato');
body {
  margin-top: 70px; /* Required padding for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */
  font-family: 'Lato', sans-serif;
}

.logo {
  height: 80px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.header-image {
  margin-top: 50px;
  display: block;
  width: 100%;
  text-align: center;
  background: url('./header-image.png') no-repeat center top scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.linkbutton {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  border-bottom: 1px solid #444;
  cursor: pointer;
}

.headline {
  padding: 40px 0;
}

.headline h1 {
  font-size: 40px;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
}

.headline h2 {
  font-size: 25px;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
}

.featurette-divider {
  margin: 40px 0px;
}

.featurette {
  overflow: hidden;
}

.featurette-image.pull-left {
  margin-right: 40px;
}

.featurette-image.pull-right {
  margin-left: 40px;
}

.featurette-heading {
  font-size: 30px;
  text-align: left;
}

footer {
  margin: 50px 0;
}

@media (max-width: 1200px) {
  .headline h1 {
    font-size: 40px;
  }

  .headline h2 {
    font-size: 25px;
  }

  .featurette-divider {
    margin: 50px 0;
  }

  .featurette-image.pull-left {
    margin-right: 20px;
  }

  .featurette-image.pull-right {
    margin-left: 20px;
  }

  .featurette-heading {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .headline h1 {
    font-size: 30px;
  }

  .headline h2 {
    font-size: 20px;
  }

  .featurette-divider {
    margin: 40px 0;
  }

  .featurette-image {
    max-width: 50%;
  }

  .featurette-image.pull-left {
    margin-right: 10px;
  }

  .featurette-image.pull-right {
    margin-left: 10px;
  }

  .featurette-heading {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0 15px;
  }

  .featurette-divider {
    margin: 40px 0;
  }

  .featurette-heading {
    font-size: 25px;
  }
}

@media (max-width: 668px) {
  .headline h1 {
    font-size: 40px;
  }

  .headline h2 {
    font-size: 25px;
  }

  .featurette-divider {
    margin: 30px 0;
  }
}

@media (max-width: 640px) {
  .headline {
    padding: 75px 0 25px 0;
  }

  .headline h1 {
    font-size: 35px;
  }

  .headline h2 {
    font-size: 20px;
  }
}

@media (max-width: 375px) {
  .featurette-divider {
    margin: 10px 0;
  }

  .featurette-image {
    max-width: 100%;
  }

  .featurette-image.pull-left {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .featurette-image.pull-right {
    margin-bottom: 10px;
    margin-left: 0;
  }
}
