.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.lead{

  text-align: left;
}
.lead ul{
  margin-top:20px;
    font-size:17px;
}
.lead_right{
 }
 .tradeoff_animation{
  max-width: 70%;
}
.customization_animation{
  max-width: 60%;
}
.references{
  text-align:left;
}

@media (max-width: 991px){
.featurette-image.pull-left {
    margin-right: 20px;
}
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.featurette-first{
    padding-top:30px
}
